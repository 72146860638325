import React, { useState} from 'react';
import {motion} from 'framer-motion'

import './Homepage.css'
import AboutUs from '../AboutUspage/AboutUs'
import Servicepage from '../Servicepage/Servicepage'
import ContactUs from '../ContactUspage/ContactUs'
import Projects from '../ProjectsPage/Projects'


import LandingVector from '../../assets/contac_us_vector.png'
import Header from '../../components/Header/Header'

function Homepage() {

  

  const handleClick = (sectionId) => {
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = -80; // Adjust this value according to your layout
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  return (
    <div className='Homepage'>

    <div className='Homapage-con'>
        <Header/>
        <div className='Homepage-main-con'>
            <div className='Homepage-main-con-left'>
              <div className='Homepage-main-con-left-image'>
                <img src={LandingVector} alt='LandingVector'/>
              </div>
            </div>
            <motion.div className='Homepage-main-con-right'
            initial={{ x: -100, opacity: 0 , scale: 0 }}
            whileInView={{ x: 0, opacity: 1 , scale: 1}}
            transition={{ duration: 1,
              delay: 0
               }}
            >
              <motion.div className='Homepage-main-con-right-title-one'
              >
              <motion.span
                        id='title-one'
                      >
                        THE SKY IS THE LIMIT
                      </motion.span>
              </motion.div>
              <div className='Homepage-main-con-right-title-two'>
                
                <span id='title-two-part-one'>Transforming Ideas into </span>
                <span id='title-two-part-two'>Cinematic </span>
                <span id='title-two-part-three'>Brilliance</span>
              </div>
              <div className='Homepage-main-con-right-title-three'>
                <span id='title-three-part-one'>“Explore the Art of Storytelling with</span>
                <span id='title-three-part-two'> Sky Films International ,</span>
                <br/>
                <span id='title-three-part-three'>Where Every Frame Tells a Captivating Tale.”</span>
                </div>
                <div className='Homepage-main-con-right-title-four'>
                  <div className='Homepage-main-con-right-four-Botton' onClick={()=>handleClick('contact')}>
                    <span id='title-four-part-one'>Contact Us</span>
                  </div>
                </div>
            </motion.div>
        </div>
    </div>

    <div className='About-us-con' id="about">
      <AboutUs/>  
    </div>
    <div className='Service-page-con' id="services">
      <Servicepage/>
    </div>
    <div className='Our-projects' id="projects">
      <Projects/>
      </div>
    <div className='Contact-us-con' id="contact">
      <ContactUs/>
      </div>
      

    
    </div>
  )
}

export default Homepage