import React from 'react'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

import './AboutUs.css'

function AboutUs() {
  return (
    <div>
        <div className='About-us-con'>
      <div className='About-us-main-con'>
        <motion.div
          className='About-us-main-con-left'
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0 }}
        ></motion.div>
        <div className='About-us-main-con-right'>
          <div className='About-us-main-con-right-row-one'>
          <motion.div className='About-us-main-con-right-row-one-title'
          initial={{ x: 100, opacity: 0 , scale: 0 }}
          whileInView={{ x: 0, opacity: 1 , scale: 1 }}
          transition={{ duration: 1,
            delay: 0.5,
            ease: 'easeOut',
            staggerChildren: 0.2, }}
          >
            <span id='About-us-title'
            >
              WHO <br/>WE ARE</span>
            <hr id='About-us-line'  /> 
            <span id='About-us-title-two'
            >WE CREATE VIDEOS</span>
          </motion.div>
          
          <motion.div className='About-us-main-con-right-row-one-image'
          initial={{ y: -100, opacity: 0 , scale: 0 }}
          whileInView={{ y: 0, opacity: 1 , scale: 1 }}
          transition={{ duration: 1, delay: 1.5 }}>
            <span id='water-mark'>SKY</span>
          </motion.div>
          </div>
          <motion.div className='About-us-main-con-right-row-two'
          initial={{ x: 100, opacity: 0 , scale: 0 }}
          whileInView={{ x: 0, opacity: 1 , scale: 1 }}
          transition={{ duration: 1,
            delay: 0.5,
            ease: 'easeOut',
            staggerChildren: 0.2,}}>
            <motion.span id='About-us-title-three'
            >
              Our goal is to provide high value, and prompt reliable service in a stunning array of video productions. 
              <br/>We specialize in film productions.</motion.span>
              <Link to='/more-about' style={{ textDecoration: 'none' }}>
              <motion.div className='About-us-con-Botton'
              initial={{ x: 100, opacity: 0 , scale: 0 }}
              whileInView={{ x: 0, opacity: 1 , scale: 1 }}
              transition={{ duration: 1,
                delay: 1,
                ease: 'easeOut',
                staggerChildren: 0.2,}}>
                    <span id='About-us-button'>More About Us</span>
                  </motion.div>
                  </Link>
          </motion.div>
          

        </div>
      </div>
    </div>
    </div>
  )
}

export default AboutUs