import React, { useState} from 'react';
import {Link} from 'react-router-dom'
import { HiOutlineViewList } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";

import './Header.css'

import Logo from '../../assets/SKYlogo.png'


function Header() {
    const [clicked, setClicked] = useState(false);

    const handleClick = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = -80; // Adjust this value according to your layout
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
      setClicked(false); // Close the mobile menu after clicking a link
    };

    


  return (
    <div className='Header-con'>
        <div className='Header-main-con'>
        <div className='Header-logo'>
            <img className='logo-image' src={Logo} alt='logo' />
        </div>
        <div id='Header-links-con' className={clicked ? "Header-links active" : "Header-links"}>

        <Link to = "/" className='nav-bar-link-style'><span onClick={() => handleClick('home')}>Home</span></Link>
          <span className='nav-bar-link-style' onClick={() => handleClick('about')}>About</span>
          <span className='nav-bar-link-style' onClick={() => handleClick('services')}>Services</span>
          <span className='nav-bar-link-style' onClick={() => handleClick('projects')}>Projects</span>
          <span className='nav-bar-link-style' onClick={() => handleClick('contact')}>Contact</span>
        </div>
        <div className='Header-login'>
            <FaRegUser size={25} color='#fff' />
        </div>
        <div id='mobile' onClick={() => setClicked(!clicked)}>
          {clicked ? <RiCloseLine id='bar' /> : <HiOutlineViewList id='bar' />}
        </div>
        </div>
        

    </div>
  )
}
export default Header