import React from 'react'
import './ServicePage.css'

import {motion} from 'framer-motion'

import Thinking from '../../assets/Thinking.jpg'
import Color from '../../assets/Color_grading.png'
import Visual from '../../assets/visual_effect.png'
import Video from '../../assets/video_editing.png'
import Music from '../../assets/music.png'

function Servicepage() {
  return (
    <div className='service-page-con'>
        <div className='service-con-main-container'>
            <div className='service-con-main-container-left'>
                <div className='service-con-main-container-left-row-1'>
                    <motion.div className='service-con-main-container-left-row-1-col-1'
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}>
                        <span id='service-title'>WHAT <br/> WE DO</span>
                        <hr id='Service-line'  /> 
                        <span id='service-sub-title'>Our experts are able to provide a wide range of services.</span>
                    </motion.div>
                    <motion.div className='service-con-main-container-left-row-1-col-2'
                    initial={{ x: 100, opacity: 0 , scale: 0 }}
                    whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                    transition={{ duration: 1,
                      delay: 0,
                      ease: 'easeOut',
                      staggerChildren: 0.2, }}>
                        <div className='image-box' >
                        <div className='service-image' style={{ backgroundImage: `url(${Thinking})`, backgroundSize: 'cover' }}>
                        
                        </div>
                        
                        <div className='describe'>
                            <div className='service-topic'>
                        <span id='service-sub-title-one'>Production Planning</span>
                        </div>
                        <span id='service-sub-title-two'>"Unlock success with our Production Planning service. A clear and well-organized production schedule ensures a flawless shoot, keeping everything on track, within budget, and on schedule."
</span>
                        </div>
                        </div>
                    </motion.div>
                    <motion.div className='service-con-main-container-left-row-1-col-2'
                    initial={{ x: 100, opacity: 0 , scale: 0 }}
                    whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                    transition={{ duration: 1,
                      delay: 0,
                      ease: 'easeOut',
                      staggerChildren: 0.2, }}>
                    <div className='image-box'>
                    <div className='service-image' style={{ backgroundImage: `url(${Color})`, backgroundRepeat: 'no-repeat', height: 100, marginTop:10 }}>
                        
                        </div>
                        
                        <div className='describe'>
                            <div className='service-topic'>
                            <span id='service-sub-title-one'>Color Grading</span>
                        </div>
                        <span id='service-sub-title-two'>"Elevate your visuals with Color Grading. Our experts use curated palettes to evoke emotions and establish a unified aesthetic, going beyond correction to craft a distinct visual tone for your photos and videos."

</span>
                        </div>
                        </div>
                    </motion.div>
                </div>
                <div className='service-con-main-container-left-row-2'>
                    <motion.div className='service-con-main-container-left-row-2-col-1'
                    initial={{ x: 100, opacity: 0 , scale: 0 }}
                    whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                    transition={{ duration: 1,
                      delay: 0,
                      ease: 'easeOut',
                      staggerChildren: 0.2, }}>
                    <div className='image-box' >
                        <div className='service-image' style={{ backgroundImage: `url(${Visual})`, backgroundSize: 'cover' }}>
                        </div>
                        
                        <div className='describe'>
                            <div className='service-topic'>
                            <span id='service-sub-title-one'>Visual Effects</span>
                        </div>
                        <span id='service-sub-title-two'>"Immerse in stunning visuals with our VFX services, featuring live-action special effects, realistic digital environments, 3D model creation, animated movement, and expert compositing."
                        </span>
                        </div>
                    </div>
                    </motion.div>
                    <motion.div className='service-con-main-container-left-row-2-col-1'
                    initial={{ x: 100, opacity: 0 , scale: 0 }}
                    whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                    transition={{ duration: 1,
                      delay: 0,
                      ease: 'easeOut',
                      staggerChildren: 0.2, }}>
                    <div className='image-box' >
                    <div className='service-image' style={{ backgroundImage: `url(${Video})`,  width: 120 , backgroundRepeat: 'no-repeat' , marginTop:20 , marginLeft:100 } }>
                        </div>
        
                        <div className='describe'>
                            <div className='service-topic'>
                            <span id='service-sub-title-one'>Video Editing</span>
                        </div>
                        <span id='service-sub-title-two'>"Experience the art of storytelling through Video Editing. Our skilled editors organize and transform video footage, crafting a tailored narrative that aligns perfectly with your vision and meets your specific requirements."
                        </span>
                        </div>
                    </div>
                    </motion.div>
                    <motion.div className='service-con-main-container-left-row-2-col-1'
                    initial={{ x: 100, opacity: 0 , scale: 0 }}
                    whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                    transition={{ duration: 1,
                      delay: 0,
                      ease: 'easeOut',
                      staggerChildren: 0.2, }}>
                    <div className='image-box' >
                    <div className='service-image' style={{ backgroundImage: `url(${Music})`, backgroundSize: 'cover' }}>
                        </div>
                        
                        <div className='describe'>
                            <div className='service-topic'>
                            <span id='service-sub-title-one'>Music Production</span>
                        </div>
                        <span id='service-sub-title-two'>"Elevate your sound with our Music Production services. From voicing and voice artist coordination to mixing and mastering, we create, capture, manipulate, and preserve music to perfectly match your needs."
                        </span>
                        </div>
                    </div>
                    </motion.div>
                </div>
            </div>
            <motion.div className='service-con-main-container-right'
            initial={{ x: -100, opacity: 0  }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1.5,
              delay: 0.5
               }}>

            </motion.div>
        </div>
    </div>
  )
}

export default Servicepage