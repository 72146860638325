import React from 'react'
import './Footer.css'

import {motion} from 'framer-motion'

import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { TiSocialYoutube } from "react-icons/ti";
import { FaTiktok } from "react-icons/fa6";

import { TiSocialFacebook } from "react-icons/ti";

function Footer() {
  return (
    <motion.div className='footer-main-con'
    initial={{ y: -50, opacity: 0   }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1,
          delay: 0
           }}>
            <div className='footer-main'>
        <div className='footer-main-container'>
            <div className='footer-main-container-one'>
                <span id='footer-title'>Sky Films International</span>
                <div className='footer-main-container-address'>
                    <FaLocationDot size={20} color='#fff' />
                    <span id='footer-info'>4/2 Sri Sunandarama Rd,Kohuwala.</span>
                </div>
                <div className='footer-main-container-address'>
                    <FaPhone size={20} color='#fff' />
                    <span id='footer-info'>070 678 7787</span>
                </div>
                <div className='footer-main-container-address'>
                    <MdEmail size={20} color='#fff' />
                    <span id='footer-info'>info@skyfilmsinternational.com</span>
                </div>
                <div className='footer-main-container-socialmedia'>
                    <a href = "https://www.youtube.com/@skyfilmsinternational"><TiSocialYoutube size={25} color='#fff'  style={{ marginRight: '10px' }} /></a>
                    <a href = "https://www.tiktok.com/@skyfilmsinternational?_t=8jWbb4C9g6j&_r=1"><FaTiktok size={18} color='#fff' style={{ marginRight: '10px' }}/></a>
                    <a href = "https://www.facebook.com/share/Lydb9rCgWyRfgY7x/?mibextid=%22%22"><TiSocialFacebook size={25} color='#fff' style={{ marginRight: '10px' }}/></a>
                </div>

            </div>
            <div className='footer-main-container-two'>
            <span id='footer-title'>Services</span>
            <div className='footer-two-links'>
                <span id='footer-links'>Production Planning</span>
                <span id='footer-links'>Color Grading</span>
                <span id='footer-links'>Visual Effects</span>
                <span id='footer-links'>Video Editing</span>
                <span id='footer-links'>Music Production</span>
            </div>
            </div>
            <div className='footer-main-container-two'>
            <span id='footer-title'>Company</span>
            <div className='footer-two-links'>
                <span id='footer-links'>About</span>
                <span id='footer-links'>Terms</span>
                <span id='footer-links'>Privacy & Policy</span>
                <span id='footer-links'>Careers</span>
                
            </div>
            </div>
            <div className='footer-main-container-two'>
            <span id='footer-title'>More</span>
            <div className='footer-two-links'>
                <span id='footer-links'>Help & FAQ’s</span>
                
            </div>
            
            </div>
            
            </div>
            <div className='footer-main-container-raw-2'>
            <span id='footer-copyrights'>Copyright © 2024 Sky Films International</span>
            </div>
        </div>
    </motion.div>

  )
}

export default Footer