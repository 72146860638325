import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProjectsComponent.css'
import {motion} from 'framer-motion'

function ProjectsComponent() {

  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState('All');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url;
        if (category === 'All') {
          url = 'http://localhost:4000/api/project/getAllProjects';
        } else {
          url = `http://localhost:4000/api/project/getAllProjectsByCategory/${category}`;
        }

        const response = await axios.get(url);
        setProjects(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [category]); // Run useEffect whenever the category changes

  
    const [selectedCategory, setSelectedCategory] = useState('All');
  

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    setSelectedCategory(selectedCategory);
  };

  return (
    <div className='project-component-con'>
      <div className='project-component-grid'>
        <motion.div className='project-component-select'
        initial={{ x: 100, opacity: 0 , scale: 0 }}
        whileInView={{ x: 0, opacity: 1 , scale: 1 }}
        transition={{ duration: 1,
          delay: 0,
          ease: 'easeOut',
          staggerChildren: 0.2, }}>
          <div className='project-component-select-con'>
          <div
        className={`select-title-first ${selectedCategory === 'All' ? 'selected' : ''}`}
        onClick={() => handleCategoryChange('All')}>
              <span id='select-title'>All</span>

            </div>

            <div
        className={`select-title ${selectedCategory === 'Music Video' ? 'selected' : ''}`}
        onClick={() => handleCategoryChange('Music Video')}>
              <span id='select-title'>Music Videos</span>
            </div>


            <div
        className={`select-title ${selectedCategory === 'Advertisement' ? 'selected' : ''}`}
        onClick={() => handleCategoryChange('Advertisement')}
      >
              <span id='select-title'>Advertisements</span>
            </div>

            
            <div
        className={`select-title-last ${selectedCategory === 'Short Film' ? 'selected' : ''}`}
        onClick={() => handleCategoryChange('Short Film')}
      >
              <span id='select-title'>Short films</span>
            </div>
          </div>
          
        </motion.div>
        <div className='project-component-main-con' style={{ overflowY: 'scroll' }}>
          
        {projects.map((project) => (
                <motion.div className = 'project-component' key={project.id}
                initial={{ x: 100, opacity: 0 , scale: 0 }}
                whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                transition={{ duration: 1,
                  delay: 0,
                  ease: 'easeOut',
                  staggerChildren: 0.2, }} >
                    <span id='project-name'>{project.title}</span>
                    <iframe
                        width="280"
                        height="180"
                        src={`https://www.youtube.com/embed/${getYouTubeVideoId(project.link)}`}
                        title={project.title}
                        allowFullScreen
                    ></iframe>
                </motion.div>
            ))}
        </div>
      </div>
    </div>
  )
}

function getYouTubeVideoId(url) {
  // Regular expression to match YouTube URL patterns
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Use the regex to extract the video ID
  const match = url.match(youtubeRegex);

  // If there's a match, return the video ID, otherwise return an empty string
  return match ? match[1] : '';
}

export default ProjectsComponent