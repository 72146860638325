
import './ContactUs.css'
import Footer from '../../components/Footer/Footer';
import React, { useState } from 'react';
import axios from 'axios';
import {motion} from 'framer-motion'
import { toast } from "react-hot-toast";

function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {

        event.preventDefault();
      
        try {
          const Response = await axios.post('http://localhost:4000/api/message/createMessage', {
            name,
            email,
            message,
          });

          if (Response.data) {
            setName('');
            setEmail('');
            setMessage('');
            
            toast.success("Your request has been sent successfully!");   
          }
      
        } catch (error) {
            console.error('Error creating project:', error.message);
            toast.error("Something went wrong");
          
        }
      
        console.log({
            name,
            email,
            message,
        });
      };

    return (
        <div className='Contact-us-con'>
            <div className='Contact-us-con-main-container'>
                <motion.div className='Contact-us-con-main-container-left'
                initial={{ x: -100, opacity: 0 , scale: 0 }}
                whileInView={{ x: 0, opacity: 1 , scale: 1}}
                transition={{ duration: 1.5,
                  delay: 0
                   }}>
                    <div className='Contac-us-title'>
                        <span id='Contact-us-title-one'>Let's Talk</span>
                        <hr id='contact-us-line' />
                        <span id='Contact-us-title-two'>We are committed to processing the information in order to contact you and talk about your project.</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='form-name'>
                            
                            <input
                                type='text'
                                id='name-filed'
                                value={name}
                                placeholder='Name'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            
                            <input
                                type='email'
                                id='email-filed'
                                value={email}
                                placeholder='Email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            
                            <textarea
                                id='message-filed'
                                value={message}
                                placeholder='Message'
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                        </div>
                        <button className='contact-submit-button' type='submit'>SUBMIT</button>
                    </form>
                </motion.div>
                <div className='Contact-us-con-main-container-right'></div>
            </div>
            <Footer/>
        </div>
    );
}

export default ContactUs