import React, { Fragment } from 'react'
import {Route , Routes , BrowserRouter as Router} from 'react-router-dom'
import Homepage from './pages/Homepage/Homepage';
import MoreAboutUs from './pages/MoreAboutUsPage/MoreAboutUs';
import {Toaster} from 'react-hot-toast'


function App() {
  return (
    <Fragment>
      <Toaster />
        <Router>
          <Routes>
            
          <Route path="/" element={<Homepage/>} />
          <Route path="/more-about" element={<MoreAboutUs/>} />
  
          </Routes>
        </Router>
    </Fragment>
  );
}

export default App;
