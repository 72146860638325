
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './project.css'
import {motion} from 'framer-motion'
import ProjectsComponent from '../../components/Projects/ProjectsComponent'

function Projects() {

    const [Data,setData] = useState([]);
    const [completeProject,setCompleteProject] = useState('');
    const [ongoingProject,setOngoingProject] = useState('');

    useEffect(() => {
        const fetchData = async () => {
          try {
            let url;
            
              url = 'http://localhost:4000/api/numberproject/getNumProject';

            const response = await axios.get(url);
            setData(response.data);
          } catch (error) {
            console.log(error);
          }
        };

        fetchData();
        
    
        
      },[]);

      

      useEffect(() => {
        if (Data.length > 0) {
          const firstDataItem = Data[0];
          setCompleteProject(firstDataItem.numProject);
          setOngoingProject(firstDataItem.numOnGoing);
        }
      }, [Data]);

      

      

  return (
    <div className='Projects-con' >
        <div className='Projects-main-con'>
            <div className='Projects-main-con-left'>
                <div className='Projects-main-con-left-row-1'>
                    <motion.div className='Projects-main-con-left-row-1-title'
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}>
                        <span id='Projects-title'>Featured 
                        <br/>
                        Projects</span>
                        <hr id='project-line'  /> 
                        <span id='Projects-title-two'>"Completing a project is not just crossing the finish line; it's the culmination of dedication, collaboration, and the artistry of turning ideas into tangible success."</span>
                    </motion.div>

                </div>
                <motion.div className='Projects-main-con-left-row-2'
                initial={{ x: 100, opacity: 0 , scale: 0 }}
                whileInView={{ x: 0, opacity: 1 , scale: 1 }}
                transition={{ duration: 1,
                  delay: 0,
                  ease: 'easeOut',
                  staggerChildren: 0.2, }}>
                    <div className='Projects-main-con-left-row-2-col'>
                        <div className='total-projects'>
                            
                            <div className='total-projects-number'>
                                <span id='total-projects-number'>{completeProject} +</span>
                            </div>
                            <div className='total-projects-title'>
                                <span id='total-projects-title'>Completed Projects</span>
                            </div>
                        </div>
                    </div>
                    <div className='Projects-main-con-left-row-2-col'>
                        <div className='total-projects'>
                        <div className='total-projects-number'>
                                <span id='total-projects-number'>{ongoingProject} +</span>
                            </div>
                            <div className='total-projects-title'>
                                <span id='total-projects-title'>Ongoing Projects</span>
                            </div>
                        </div>
                    </div>
                    
                </motion.div>

            </div>
            <div className='Projects-main-con-right'>
                
                <div className='Projects-main-con-right-row-2' >
                    <ProjectsComponent/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Projects