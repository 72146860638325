import React from 'react'
import {motion} from 'framer-motion'

import './MoreAboutUs.css'

import Footer from '../../components/Footer/Footer';

import Header from '../../components/Header/Header'
import Namal from '../../assets/namal jayasinghe.jpeg'
import Pasan from '../../assets/pasan.jpg'

function MoreAboutUs() {
  return (
    <div className='MoreAboutUs-main-com'>
        <div className='MoreAboutUs-main-com-header'>
          <Header/>
        </div>
        <motion.div className='MoreAboutUs-main-com-body-one'
        initial={{ x: -100, opacity: 0 , scale: 0 }}
        whileInView={{ x: 0, opacity: 1 , scale: 1}}
        transition={{ duration: 1,
          delay: 0
           }}>
            <div className='MoreAboutUs-main-com-body-one-title'>
                <span>WHO WE ARE</span>
            </div>
            <div className='MoreAboutUs-main-com-body-one-content'>
                <span>Our goal is to provide high value, and prompt reliable service in a stunning array of video productions. 
              </span>
            </div>
            <div className='MoreAboutUs-main-com-body-one-images'>
                <div className='MoreAboutUs-main-com-body-one-images-one'>

                </div>
            </div>
        </motion.div>
        <div className='MoreAboutUs-main-com-body-two'>
          <div className='MoreAboutUs-main-com-body-two-main'>
              <motion.div className='MoreAboutUs-main-com-body-two-main-left'
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0 }}>
                <span className='our-story'>OUR STORY</span>
                <div className='MoreAboutUs-main-com-body-two-main-left-p1'>
                  <span>We are committed to pushing the boundaries of visual storytelling, utilizing state-of-the-art technology and a team of skilled professionals to create engaging and memorable cinematic experiences.</span>
                    </div>
              </motion.div>
              <motion.div className='MoreAboutUs-main-com-body-two-main-right'
              initial={{ x: 100, opacity: 0 , scale: 0 }}
              whileInView={{ x: 0, opacity: 1 , scale: 1 }}
              transition={{ duration: 1,
                delay: 0.5,
                ease: 'easeOut',
                staggerChildren: 0.2, }}>
              <div className='MoreAboutUs-main-com-body-two-main-right-p1'>
              <span>SKY Films International (Pvt) Ltd. is a dynamic creative video production company spearheaded by Namal Jayasinghe and Pasan Chandrasekara two individuals who have been in the industry for many years and bring vast Knowledge with them to the table. </span>
                  
                    </div>
                  <div className='MoreAboutUs-main-com-body-two-main-right-p1'>
                    <span>Along with “Namal” and “Pasan” we also have “Heshan Kodagoda” on board as a fulltime permanent director who we promote as our three inhouse resources with whom we are able to offer very competitive production costs. Furthermore, as an added benefit the three directors work in collaboration as a team (whichever director dose the job) to support each other and contribute with there own strong point. Also, this trio has a long history together and blend well with each other.</span>
                    </div>
                    <div className='MoreAboutUs-main-com-body-two-main-right-p1'>
                      <span> We also offer a long list of freelance directors who are in the market and who are willing to work with us as their production partners, many of whom have worked with us in the past and could offered if requested provided they are available within the required time line.</span>
                    </div>
              </motion.div>
          </div>

        </div>
        <div className='MoreAboutUs-main-com-body-three'>
          <div className='MoreAboutUs-main-com-body-three-main'>
            <motion.div className='MoreAboutUs-main-com-body-three-main-title'
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0 }}>
              <span>LEADERSHIP</span>
            </motion.div>
            <div className='MoreAboutUs-main-com-body-three-main-team'>
              <motion.div className='team-member'
              initial={{ x: 100, opacity: 0 , scale: 0 }}
              whileInView={{ x: 0, opacity: 1 , scale: 1 }}
              transition={{ duration: 1,
                delay: 0.5,
                ease: 'easeOut',
                staggerChildren: 0.2, }}>
                <div className='team-member-left'>
                <div className='team-member-image'>
                  <img src={Pasan} width='200px' height={200} alt='Pasan Chandrasekara'/>
                </div>
                <div className='team-member-name'>
                  <span>Pasan Chandrasekara</span>
                  </div>
                  </div>
                  <div className='team-member-right'>
                  <div className='team-member-description'>
                    <span>Pasan Chandrasekera is a versatile character a jak of all trades, he is a Director, Cinematographer, Motion Graphics Editor, Editor & Colorist. He brings along his vast experience and knowledge accumulated at his past colorful working collaborations at numerus levels at many establishments. Adding to his tight and busy schedule he continues to lecturer at the “University College Rathmalana” imparting his knowledge onto the next generation. Furthermore, he has equipped himself with computer software and handwear knowledge, enabling him to understand  <br></br><br></br>
                    He has worked at many notable established namely “Rangiri Sri Lanka Radio & Television”, “WideAngle Productions”, “Channel C”, “The Video Academy” and “SKY Colombo” before embarking on his new journey as the co-director of “SKY Films International (Pvt) Ltd.”</span>
                  </div>
                  </div>

              </motion.div>
              <motion.div className='team-member'
              initial={{ x: 100, opacity: 0 , scale: 0 }}
              whileInView={{ x: 0, opacity: 1 , scale: 1 }}
              transition={{ duration: 1,
                delay: 0.5,
                ease: 'easeOut',
                staggerChildren: 0.2, }}>
                <div className='team-member-left'>
                <div className='team-member-image'>
                  <img src={Namal} width='200px' height={200} alt='Namal Jayasinghe'/>
                </div>
                <div className='team-member-name'>
                <span style={{ alignItems: 'center' }}>Namal Jayasinghe</span>
                  </div>
                  </div>
                  <div className='team-member-right'>
                  <div className='team-member-description'>
                    <span>Namal Jayasinghe is a highly acclaimed actor, director, screenwriter, and educator renowned for his exceptional work in Sahodaraya (2017), Pithru (2022), and August Sun (2003). He is a multi-talented individual, having also directed theatre plays and TV commercials. His artistic endeavors are characterized by a strong focus on social and political themes, particularly those relevant to post-war Sri Lanka. His screenplays have earned numerous awards and widespread recognition, captivating audiences far and wide.<br></br><br></br>
                    Notably, his screenplay for the popular tele series 'Sathya' garnered both praise and criticism for its unexpected and open ending. Many speculated that the show's conclusion was a result of government pressure due to its political drama and topical discussions. However, Namal Jayasinghe clarified in several interviews that the ending was a deliberate and intentional choice, aimed at sending a message to the audience. He intended to urge viewers not to wait for fictional heroes in the series but to take action and become real heroes in society, actively effecting change.<br></br><br></br>
                    In collaboration with the renowned actress Kaushalya Fernando, Namal has launched a diploma theatre program under the auspices of Lanka Children's and Youth Theatre Foundation (LCYTF), Act No 3 of 2007. This program aims to provide aspiring individuals interested in drama and theatre with a unique learning platform. Prospective students will have the opportunity to learn directly from experienced professionals in various fields, enabling them to become skilled in acting, scriptwriting, directing, and dance theatre. The primary goal of this initiative is to nurture and produce creative artists who can contribute significantly to the world of performing arts. </span>
                  </div>
                  </div>

              </motion.div>
             
            </div>
          </div>
        </div>
        <div className='footer--'>
          <Footer/>
        </div>
        
    </div>
  )
}

export default MoreAboutUs